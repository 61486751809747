import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@mui/material';

const CustomDialog = ({ open, onClose, title, content, confirmText }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropProps={{
        style: {
          backgroundColor: 'transparent', // 어둡게 하면서 약간 투명하게 설정
          backdropFilter: 'blur(2px)', // 배경을 흐리게 처리
          WebkitBackdropFilter: 'blur(2px)' // iOS Safari 지원을 위한 접두사 추가
        }
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',  // 모서리를 둥글게 설정
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)',  // 부드러운 쉐도우 추가
          minWidth: '300px'
        }
      }}
    >
      {/* <DialogTitle>{title || '알림'}</DialogTitle> */}
      <DialogContent>
        <DialogContentText
          sx={{
            fontSize: '17px', // 폰트 크기 설정
            color: '#111111'  // 텍스트 색상 설정
          }}>
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
            justifyContent: 'center' // 수평 중앙 정렬
        }}>
        <Button onClick={onClose}
          sx={{
            fontSize: '16px', // 폰트 크기 설정
            color: '#217086'  // 버튼 텍스트 색상 설정
          }}>
          {confirmText || '확인'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomDialog;